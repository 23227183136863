import { graphql } from "gatsby";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import MetaTags from "../components/MetaTags";
import ty from "../styled-tachyons";
import {
  DarkGraySection,
  ns,
  PageHeader,
  SectionHeader,
  WhiteSection,
  RichText,
} from "../styles";
import {
  ArtistTagsContainer,
  ArtistTags,
  ArtistTag,
} from "../components/ArtistTags";
import { Carousel } from "./Carousel";
import { canNavEvent } from "../utils/events";

const EventsPageStyle = styled.div`
  ${ArtistTag} {
    ${ty`ttn`}
  }

  ${WhiteSection} ${SectionHeader} {
    ${ty`f4 mb4`}
  }
`;

const EventsSection = styled(DarkGraySection)`
  ${ty`pb3`}
  ${RichText} {
    ${ty`center f5 tc`}
    ${ns("max-width: 56.25rem")}
  }
`;

const eventCardTitle = ({ category }) => {
  return category;
};
export const EventsPageTemplate = ({
  title,
  html,
  excerpt,
  allEvents = [],
  featuredEvents = [],
}) => (
  <EventsPageStyle>
    <MetaTags title={title} description={excerpt} />
    <EventsSection>
      <PageHeader
        css={`
          ${ty`tc`}
        `}
      >
        {title}
      </PageHeader>
      <RichText dangerouslySetInnerHTML={{ __html: html }} />
    </EventsSection>
    <Carousel
      projects={featuredEvents}
      getCardTitle={eventCardTitle}
    ></Carousel>
    <WhiteSection>
      <SectionHeader>View the Events</SectionHeader>
      <ArtistTagsContainer>
        <ArtistTags numItems={allEvents.length}>
          {allEvents.map(({ name, link }, i) => {
            let target = link && link.indexOf("/") === 0 ? "" : "_blank";
            return (
              <ArtistTag
                key={i.toString() + name}
                href={link}
                target={target}
                title={name}
              >
                {name}
              </ArtistTag>
            );
          })}
        </ArtistTags>
      </ArtistTagsContainer>
    </WhiteSection>
  </EventsPageStyle>
);

EventsPageTemplate.propTypes = {};

const getEventLink = (eventNode) => {
  let link = canNavEvent(eventNode)
    ? eventNode.fields.slug
    : eventNode.frontmatter.link;

  return link;
};

const EventsPage = (p) => {
  const { data } = p;
  const { frontmatter, html, excerpt } = data.markdownRemark;
  const { featuredEvent } = frontmatter;

  const allEventNodes = data.allEvents.edges;
  const featuredEventNodes = data.featuredEvents.edges.slice().reverse();
  //console.log(p);
  const allEvents = React.useMemo(() => {
    if (!allEventNodes) {
      return [];
    }

    return allEventNodes.map(({ node }) => {
      // console.log({ node });
      let link = getEventLink(node);
      return {
        link,
        name: node.frontmatter.flagTitle,
      };
    });
  }, [allEventNodes]);
  //console.log(p);
  const featuredEvents = React.useMemo(() => {
    if (!featuredEventNodes) {
      return [];
    }

    let sorted = featuredEventNodes;
    if (featuredEvent) {
      let eventNode = _.find(sorted, {
        node: { frontmatter: { uuid: featuredEvent } },
      });
      if (eventNode) {
        console.log({ eventNode });
        sorted = _.without(sorted, eventNode);
        sorted = [...sorted, eventNode];
      }
    }

    return sorted.map(({ node }) => {
      return {
        ...node.frontmatter,
        artistName: _.get(node, "fields.artistNode.frontmatter.name"),
        imageUrl: node.frontmatter.carouselImage || node.frontmatter.coverImage,
        projectUrl: getEventLink(node),
      };
    });
  }, [featuredEventNodes, featuredEvent]);

  return (
    <Layout>
      <EventsPageTemplate
        title={frontmatter.title}
        excerpt={excerpt}
        html={html}
        allEvents={allEvents}
        featuredEvents={featuredEvents}
      />
    </Layout>
  );
};

EventsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default EventsPage;

export const pageQuery = graphql`
  query EventsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "events-page" } }) {
      html
      excerpt
      frontmatter {
        title
        featuredEvent
      }
    }

    allEvents: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___sortOrder] }
      filter: { frontmatter: { entityType: { eq: "event" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            flagTitle
            link
            description
          }
        }
      }
    }

    featuredEvents: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___startDate] }
      filter: { frontmatter: { entityType: { eq: "event" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            uuid
            category
            title
            subTitle
            subSubTitle
            link
            artist
            description

            coverImage {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            carouselImage {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
